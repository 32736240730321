import React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { saveSelectedMenu } from "../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { notificationGetAction } from "../redux/actions";

const Menu = ({ data }) => {
	const { t, i18n } = useTranslation();
	const selected_lang = i18n.language;
	const user = useSelector(({ user }) => user);
	const dispatch = useDispatch();
	const config = useSelector((state) => state.app.config);

	const languageChangeHandler = () => {
		
		let curSelectedLang = selected_lang.toLowerCase() === "fr" ? "en" : "fr";
		// For LOCAL ONLY
		// localStorage.setItem("i18nextLng", curSelectedLang);
		// i18n.changeLanguage(curSelectedLang);
		// return;
		// For ENVIRONMENT
		if (config?.additionalDomain?.length > 0) {
			let data = config.additionalDomain.filter((v) => v.language.toLowerCase() === curSelectedLang);
			if (data.length > 0) {
				window.location.href = `${data[0].domain}${window.location.pathname}${window.location.search}`;
			} else {
				window.location.href = `${config.domain}${window.location.pathname}${window.location.search}`;
			}
		} else {
			localStorage.setItem("i18nextLng", curSelectedLang);
			i18n.changeLanguage(curSelectedLang);
		}
	};
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
	}, [i18n.language]);
	const loadNotification = () => {
		const accessToken = user?.accessToken;
		if (accessToken) {
			dispatch(notificationGetAction(""));
		}
	};
	React.useEffect(() => {
		loadNotification();
	}, []);
	React.useEffect(() => {
		const interval = setInterval(() => {
			loadNotification();
		}, 300000);
		return () => clearInterval(interval);
		//eslint-disable-next-line
	}, []);
	return (
		<Fragment>
			<button id="button-navbar-toggler" className="navbar-toggler mob-menu-pos" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span id="span-navbar-toggler-icon" className="navbar-toggler-icon"></span>
			</button>

			<a id="href-lang" href="https://www.costco.ca/procter-and-gamble-brand-showcase.html" target="new" className={`btn btn-primary btn-primary-header ff-StdHeavy d-lg-none mr-2 p-2 btn-primary-mobile-header`}>
				{t("MENU.BTN")}
			</a>

			<div className="collapse navbar-collapse" id="navbarSupportedContent">
				<ul className="navbar-nav ml-3 ml-lg-5 mr-4 w-100">
					{/*[START:HOME]*/}
					<li className="nav-item pl-xl-5">
						<a className="nav-link nav-link-custom" id="href-home" href={`/`}>
							{t("MENU.HOME")}
						</a>
					</li>
					{/*[END:HOME]*/}
					{/*[START:SUPPORT]*/}
					<li className="nav-item">
						<a id="href-support" className="nav-link" href={`/support`}>
							{t("MENU.SUPPORT")}
						</a>
					</li>
					{/*[END:SUPPORT]*/}
					{/*[START:FAQ]*/}
					{/* <li className="nav-item">
						<a id="href-faq" className="nav-link" href={`/faq`}>
							{t("MENU.FAQ")}
						</a>
					</li> */}
					{/*[END:FAQ]*/}
					<li className="nav-item">
						{/* "/#participate-content" */}
						<a className="nav-link nav-link-custom" id="href-participating-products" href={`/participating-products`}>
							{t("MENU.PP")}
						</a>
					</li>
					{/*[START:LANGUAGE]*/}
					<li className="nav-item">
						<button id="href-lang" className="nav-link btn btn-link" onClick={languageChangeHandler}>
							{t("MENU.LANG")}
						</button>
					</li>
					{/*[END:LANGUAGE]*/}
					<li className="nav-item">
						<a id="href-lang" href="https://www.costco.ca/procter-and-gamble-brand-showcase.html" target="new" className="btn btn-primary btn-primary-header ff-StdHeavy d-none d-lg-block">
							{t("MENU.BTN")}
						</a>
					</li>
				</ul>
			</div>

			{/* <TopRight /> */}
		</Fragment>
	);
};

export default Menu;
