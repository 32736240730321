import React from "react";
import { getManu } from "../../redux/actions";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const Footer = () => {
	const { t } = useTranslation();
	const handleShow = () => {
		if (window.Optanon) {
			window.Optanon.ToggleInfoDisplay();
		}
	};
	return (
		<footer role="contentinfo">
			<Container className="footer">
				<div className="footer-link " aria-label="footerMenu">
					<ul>
						<li>
							<a href={t("FOOTER.TNC_URL")} target="new">
								<u>{t("FOOTER.TNC_COPY")}</u>
							</a>{" "}
							|
						</li>
						<li>
							<a href={t("FOOTER.PNG_TNC_URL")} target="new">
								<u>{t("FOOTER.PNG_TNC_COPY")}</u>
							</a>{" "}
							|
						</li>
						<li>
							<a href={t("FOOTER.PRIVACY_URL")} target="new">
								<u>{t("FOOTER.PRIVACY_COPY")}</u>
							</a>{" "}
							|
						</li>
						<li>
							<a href={t("FOOTER.COOKIES_URL")} target="new">
								<u>{t("FOOTER.COOKIES_COPY")}</u>
							</a>{" "}
							|
						</li>
						<li className="pr-0">
							<a href={t("FOOTER.ADCHOICE_URL")} target="new">
								<u>{t("FOOTER.ADCHOICE_COPY")}</u>
							</a>{" "}
						</li>
						{/* <li className="pr-0">
							<a href={t("FOOTER.MYDATA_URL")} target="new">
								<u>{t("FOOTER.MYDATA_COPY")}</u>
							</a>{" "}
							|
						</li>
						<li className="pr-0">
							<a href='javascript:void(0)' onClick={handleShow}>
								<u>{t("FOOTER.ONE_TRUST")}</u>
							</a>{" "}
						</li> */}
					</ul>
				</div>
				<div>
					{/* <div dangerouslySetInnerHTML={{ __html: t("FOOTER.COPY_DECK") }} /> */}
					<p className="tierlogic text-center">
						<span className="d-block d-sm-inline-block text-center">
							{t("FOOTER.POWERED_BY")}
							{/* <a href="https://www.3tierlogic.com/" target="_blank">
								<img alt="3TL logo" src="/public/images/3tl-logo-footer.png" height="22px" />
							</a> */}
						</span>
					</p>
				</div>
			</Container>
		</footer>
	);
};
export default connect(null, { getManu })(Footer);
