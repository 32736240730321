import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import IsLoadingHOC from "./../IsLoadingHOC";
import { getPurchasePointsInfo, receiptListAction, validateUploadReceiptAction } from "./../../redux/actions";
import { useSelector } from "react-redux";
const PreviousReceiptList = (props) => {
	const { t, i18n } = useTranslation();
	const selected_lang = i18n.language;
	const user = useSelector(({ user }) => user);
	const [userEarned, setUserEarned] = useState({});
	const [tableList, setTableList] = useState([]);
	const [userPurchasePoints, setUserPurchasePoints] = useState([]);
	const [showAll, setShowAll] = useState(false);
	const floatParser = (num) => {
		if (isNaN(num)) {
			return num;
		} else if (`${num}`.indexOf(".") >= 0) {
			return num.toFixed(2);
		} else {
			return num;
		}
	};
	const parseJwt = (token) => {
		let base64Url = token.split(".")[1];
		let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		let jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split("")
				.map(function(c) {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join("")
		);

		return JSON.parse(jsonPayload);
	};
	
	/**
	 * Cooks a human readable date format
	 * @param {Date} dt Target Date Object
	 * @returns 
	 */
	const getDateString = (dt) => {
		const allMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		return `${allMonths[dt.getMonth()]} ${dt.getDate() > 9 ? "" : "0"}${dt.getDate()}, ${dt.getFullYear()}`;
	};

	useEffect(() => {
		props.setLoading(true);
		
		getPurchasePointsInfo(user?.id).then(userPurchasePointsResponse => {
			console.log("USER REWARDS", { userPurchasePoints: userPurchasePointsResponse?.data[0] });
			// eslint-disable-next-line no-extra-boolean-cast
			if (!!userPurchasePointsResponse?.data[0]) {
				setUserPurchasePoints(userPurchasePointsResponse?.data[0]?.totalFullFillDetails);
			}
		});
		
		receiptListAction()
			.then((responseData) => {
				if (responseData.httpCode === 200 && responseData.data.content) {
					const respData = responseData.data.content.map((v, ind) => {
						let dt = new Date(v.date);
						let itemDate = getDateString(dt);
						let d = JSON.parse(JSON.stringify(v));
						d.date = itemDate;
						d.qualifyingAmount = d.qualifyingAmount === 0 || d.status.toUpperCase() !== "APPROVED" ? "-" : d.qualifyingAmount;
						d.brand = d.brandName ? d.brandName : "-";
						d.points = d.processPoints ? d.processPoints : "-";
						d.ind = ind + 1;
						return d;
					});
					respData.sort((a, b) => {
						return new Date(b.date) - new Date(a.date);
					});
					setTableList(respData);
				} else {
					setTableList([]);
				}
				props.setLoading(false);
			})
			.catch((err) => {
				props.setLoading(false);
				setTableList([]);
				console.log("Response Error:", JSON.stringify(err));
			});
		validateUploadReceiptAction({}, "COSTCO_USER_RECEIPTS_SPENT_DETAILS")
			.then((responseData) => {
				if (responseData["httpCode"] >= 200 && responseData["httpCode"] <= 299 && responseData?.data?.token) {
					let tokenData = parseJwt(responseData?.data?.token);
					setUserEarned(tokenData);
				}
			})
			.catch((err) => {
				console.log("error", err);
			});
		// eslint-disable-next-line
	}, [props.reloadCounter]);
	if (tableList.length > 0) {
		return (
			<div className="w-100">
				<div className="row justify-content-center">
					<div className="col-md-12 col-xs-12 pt-3">
						<h1 className="pt-3">{`${t("MEMBER_UPLOAD.HEADING")} ${user?.name.toUpperCase()}`}</h1>
					</div>
					{selected_lang === "fr" ? (
						<>
							<div className="col-md-12 col-xs-12">
								<h2 className="pt-3 text-center black font-30px">VOUS AVEZ DÉPENSÉ* {`${floatParser(userEarned?.payload?.totalDollarsSpent || 0)}`.replace(".", ",")} $ SUR DES PRODUITS P&G CHOISIS</h2>
								<p className="text-center">
									Vous êtes à <strong className="ff-StdBold">{`${floatParser(userEarned?.payload?.dollarsUntilNextCard || 0)}`.replace(".", ",")} $</strong> de votre carte Achat numérique de Costco de 25 $!
								</p>
								<p className="text-center">
									Période d'achat du programme : 26 février 2024 au 7 avril 2024
									<br />
									Dernière journée pour soumettre des reçus : 5 mai 2024
								</p>
							</div>
							<div className="col-md-12 col-xs-12 bg-grey pt-3 px-3">
								<Row xs={1} md={3} lg={3}>
									<Col className="text-center">
										<h3 className="p-small ff-StdHeavy">
											MONTANT TOTAL
											<br />
											DÉPENSE*
										</h3>
										<p className="ff-StdHeavy themeColor">{`${floatParser(userEarned?.payload?.totalDollarsSpent || 0)}`.replace(".", ",")} $</p>
										<p className="font-10px">
											* Après les économies et avant les
											<br />
											taxes et les frais d’expédition.
										</p>
									</Col>
									<Col className="text-center">
										<h3 className="p-small ff-StdHeavy">
											VALEUR DE LA CARTE ACHAT
											<br />
											NUMÉRIQUE DE COSTCO OBTENUE
										</h3>
										<p className="ff-StdHeavy themeColor">{`${floatParser(userEarned?.payload?.costcoCashEarned || 0)}`.replace(".", ",")} $</p>
										<p className="font-10px">
											Un maximum de 50 $ par numéro
											<br />
											de membre de Costco
										</p>
									</Col>
									<Col className="text-center">
										<h3 className="p-small ff-StdHeavy">
											DOLLARS JUSQU'À
											<br />
											LA PROCHAINE CARTE ACHAT
											<br />
											NUMÉRIQUE DE COSTCO
										</h3>
										<p className="ff-StdHeavy themeColor">{`${floatParser(userEarned?.payload?.dollarsUntilNextCard || 0)}`.replace(".", ",")} $</p>
										<p className="font-10px">
											Jusqu’à un maximum de 200 $ après les économies
											<br />
											et avant les taxes et les frais d’expédition
										</p>
									</Col>
								</Row>
							</div>
						</>
					) : (
						<>
							<div className="col-md-12 col-xs-12">
								<h2 className="pt-3 text-center black font-30px">YOU'VE SPENT* ${floatParser(userEarned?.payload?.totalDollarsSpent || 0)} ON PARTICIPATING P&G PRODUCTS</h2>
								<p className="text-center">
									You are <strong className="ff-StdBold">${floatParser(userEarned?.payload?.dollarsUntilNextCard || 0)}</strong> away from a $25 Digital Costco Shop Card!
								</p>
								<p className="text-center">
									Program purchase period: February 26, 2024 - April 7, 2024
									<br />
									Last date for submission of receipts: May 5, 2024
								</p>
							</div>
							<div className="col-md-12 col-xs-12 bg-grey pt-3 px-3">
								<Row xs={1} md={3} lg={3}>
									<Col className="text-center">
										<h3 className="p-small ff-StdHeavy">
											TOTAL DOLLARS
											<br />
											SPENT*
										</h3>
										<p className="ff-StdHeavy themeColor">${floatParser(userEarned?.payload?.totalDollarsSpent || 0)}</p>
										<p className="font-10px">
											*After discounts and before taxes
											<br />
											and shipping costs
										</p>
									</Col>
									<Col className="text-center">
										<h3 className="p-small ff-StdHeavy">
											COSTCO CASH
											<br />
											EARNED
										</h3>
										<p className="ff-StdHeavy themeColor">${floatParser(userEarned?.payload?.costcoCashEarned || 0)}</p>
										<p className="font-10px">
											Maximum $50 per Costco
											<br />
											membership number
										</p>
									</Col>
									<Col className="text-center">
										<h3 className="p-small ff-StdHeavy">
											DOLLARS UNTIL NEXT
											<br />
											DIGITAL COSTCO SHOP CARD
										</h3>
										<p className="ff-StdHeavy themeColor">${floatParser(userEarned?.payload?.dollarsUntilNextCard || 0)}</p>
										<p className="font-10px">
											Up to a maximum of $200 after discounts
											<br />
											and before taxes and shipping costs
										</p>
									</Col>
								</Row>
							</div>
						</>
					)}
					<div className="col-md-11 col-xs-12 pt-3">
						<h4 className="ff-StdHeavy">
							<Trans>MEMBER_UPLOAD.HEADING2</Trans>
						</h4>
						<table className="table uploaded-receipt-list">
							<thead>
								<tr>
									<th className="pl-5">
										<Trans>MEMBER_UPLOAD.TABLE.THEAD_DATE</Trans>
									</th>
									<th>
										<Trans>MEMBER_UPLOAD.TABLE.THEAD_ACTIVITY</Trans>
									</th>
									<th>
										<Trans>MEMBER_UPLOAD.TABLE.THEAD_DOLLARS</Trans>
									</th>
								</tr>
							</thead>
							<tbody>
								{(!showAll ? tableList.slice(0, 3) : tableList).map((v, i) => {
									return (
										<tr key={v?.id || i}>
											<td className="pl-5">{v.date}</td>
											<td>{t(`MEMBER_UPLOAD.${v.status.toUpperCase()}`)}</td>
											<td>{v.qualifyingAmount === "-" ? v.qualifyingAmount : selected_lang === "fr" ? `${v.qualifyingAmount}`.replace(".", ",") + " $" : `$${v.qualifyingAmount}`}</td>
										</tr>
									);
								})}
								{
									!!userPurchasePoints && userPurchasePoints?.length > 0 && userPurchasePoints.map(({reward_name, date, amount}) => {
										const fulfillmentDate = new Date(date);
										// eslint-disable-next-line no-unused-expressions
										return (<tr key={reward_name + date + amount}>
											<td className="pl-5">{getDateString(fulfillmentDate)}</td>
											<td><Trans>MEMBER_UPLOAD.REWARD_25</Trans></td>
											<td>&nbsp;</td>
										</tr>);
									})
								}
							</tbody>
						</table>
						<button
							type="button"
							className="styleColor ff-StdBold btn"
							onClick={() => {
								setShowAll(!showAll);
							}}>
							<u>
								<Trans>{!showAll ? "MEMBER_UPLOAD.VIEW_ALL_ACTIVITY" : "MEMBER_UPLOAD.HIDE_MORE_ACTIVITY"}</Trans>
							</u>
						</button>
					</div>
				</div>
			</div>
		);
	} else {
		return "";
	}
};

export default IsLoadingHOC(PreviousReceiptList, "Loading....");
